import * as React from 'react'
import useFooterStyles from './Footer.styles'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const classes = useFooterStyles()

  return (
    <div className={`Footer ${classes.general}`}>
      © 2022 Trimbus BigBooh | webdesign door: <a className={classes.link} href='http://www.shans.be'>shans.be</a>
    </div>
  )
}

export default Footer;
