import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useHomeStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralDark}`,
        width: '100%',
        color: `${theme.palette.neutralLight}`
    }
}))

export default useHomeStyles