import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../theme"

const useFormStyles = makeStyles(theme => ({
    form: {
        padding: '20px 30px 20px 30px'
    },
    formItem: {
        padding: '.5em 0'
    }
}))

export default useFormStyles