import * as React from 'react'
import { ContactForm } from '../../ContactForm'
import useContactStyles from './Contact.styles'

interface ContactProps { }

const Contact: React.FC<ContactProps> = () => {

  const classes = useContactStyles()

  return (
    <div className={`ms-Grid ${classes.general}`} dir="ltr">
      <div className={`ms-Grid-row`}>
        <div className={`ms-Grid-col ms-sm12 ${classes.header}`}>
          contact
        </div>
      </div>
      <div className={`ms-Grid-row ${classes.center}`}>
        <div className='ms-Grid-col ms-sm12'>
          Heeft u een vraag of wenst u een afspraak te maken?
          <br />
          Neem dan contact op via 1 van onderstaande kanalen of laat een berichtje achter via het formulier.
        </div>
      </div>

      <div className={`ms-Grid-row`}>
        <div className={`ms-Grid-col ms-sm12 ms-md6`}>
          <div className={classes.contactBlock}>
            <div className={classes.contactheader}>BigBooh</div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm6`}>
                <div className={`ms-Grid-row`}>
                  <div className={`ms-Grid-col ms-sm12 ${classes.contactsubheader}`}>telefoon*</div>
                  <div className={`ms-Grid-col ms-sm12 ${classes.defaulttext}`}>0487 78 27 31</div>
                </div>
              </div>
              <div className={`ms-Grid-col ms-sm6`}>
                <div className={`ms-Grid-row`}>
                  <div className={`ms-Grid-col ms-sm12 ${classes.contactsubheader}`}>email</div>
                  <div className={`ms-Grid-col ms-sm12 ${classes.defaulttext}`}>info@bigbooh.be</div>
                </div>
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ms-md6`}>
                <div className={`ms-Grid-row`}>
                  <div className={`ms-Grid-col ms-sm12 ${classes.contactsubheader}`}>openingstijden</div>
                  <div className={`ms-Grid-col ms-sm12 ${classes.defaulttextheader}`}>maandag & dinsdag</div>
                  <div className={`ms-Grid-col ms-sm12 ${classes.defaulttext}`}>van 7 to 20u</div>
                </div>
              </div>
              <div className={`ms-Grid-col ms-sm12 ms-md6`}>
                <div className={`ms-Grid-row`}>
                  <div className={`ms-Grid-col ms-sm12 ${classes.contactsubheader}`}>regio</div>
                  <div className={`ms-Grid-col ms-sm12 ${classes.defaulttext}`}>belgische kempen</div>
                </div>
              </div>
            </div>
            <div className={`ms-Grid-row`} style={{ paddingTop: '40px' }}>
              <div className="fb-page" data-href="https://www.facebook.com/Trimbus-Big-Booh-107337691862048/"
                data-tabs=""
                data-width="260"
                data-height=""
                data-small-header="true"
                data-adapt-container-width="true"
                data-hide-cover="true"
                data-show-facepile="true">
                <blockquote cite="https://www.facebook.com/Trimbus-Big-Booh-107337691862048/"
                  className="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/Trimbus-Big-Booh-107337691862048/">Trimbus Big Booh</a>
                </blockquote>
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.textremark}`}>
                <br />
                *uw telefoontje niet meteen beantwoord? <br />
                spreek een voicemail in en we bellen zo snel mogelijk terug
              </div>
            </div>
          </div>
        </div>

        <div className={`ms-Grid-col ms-sm12 ms-md6`}>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

export default Contact
