import * as  React from 'react'

import { ITextFieldProps, TextField } from '@fluentui/react/lib/TextField'
import { useField } from 'formik'

interface IInputFieldProps {
    fieldname: string
}

const InputField: React.FC<IInputFieldProps & ITextFieldProps> = (props) => {
    const [field, meta, helpers] = useField<string | undefined>(props.fieldname)

    const handleTextChange = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        helpers.setValue(newValue)
    }, [])

 return (
     <TextField
        {...props}
        errorMessage={meta.error}
        value={field.value}
        onChange={handleTextChange}
     />
 )
}

export default InputField