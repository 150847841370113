import * as React from 'react'

import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

import useImageGalleryStyles from './ImageGallery.styles'

import photo1 from './../../images/AdobeStock_261869710.jpeg'
import photo2 from './../../images/AdobeStock_164042211.jpeg'
import photo3 from './../../images/AdobeStock_303201386.jpeg'
import photo4 from './../../images/AdobeStock_341339722.jpeg'
import photo5 from './../../images/AdobeStock_288243128.jpeg'
import photo6 from './../../images/AdobeStock_76335586.jpeg'
import photo7 from './../../images/AdobeStock_324064930.jpeg'

interface FormProps {
}

const ImageGalleryComponent: React.FC<FormProps> = () => {
    const classes = useImageGalleryStyles()

    const images = [
        {
            original: photo1,
            thumbnail: photo1,
        },
        {
            original: photo2,
            thumbnail: photo2,
        },
        {
            original: photo3,
            thumbnail: photo3,
        },
        {
            original: photo4,
            thumbnail: photo4,
        },
        {
            original: photo5,
            thumbnail: photo5,
        },
        {
            original: photo6,
            thumbnail: photo6,
        },
        {
            original: photo7,
            thumbnail: photo7,
        }
    ]

    return (
        <ImageGallery
            items={images}
            showBullets={true}
            showFullscreenButton={true}
            showNav={true}
            showPlayButton={true}
            showThumbnails={true}
            slideDuration={450}
            slideInterval={4000}
            thumbnailPosition='top'
        />
    )
}

export default ImageGalleryComponent