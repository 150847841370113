import { IComboBoxOption } from "@fluentui/react";

export interface IContactFormValues {
    name?: string
    street?: string
    postalCode?: string
    city?: IComboBoxOption | null
    phoneNumber?: string
    email?: string
    remark?: string
    day?: IComboBoxOption | null
}

export enum IDays {
    monday = 'maandag',
    tuesday = 'dinsdag',
    wednesday = 'woensdag',
    thursday = 'donderdag',
    friday = 'vrijdag',
    saterday = 'zaterdag',
    sunday = 'zondag'
}