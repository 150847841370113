import * as  React from 'react'

import { IComboBoxOption } from '@fluentui/react/lib/ComboBox'

import { usePostinfoDetail } from '../../hooks'

interface IContactFormCityProps {
    postalCode?: string
    render: (options: Array<IComboBoxOption>) => void
}

const ContactFormCity: React.FC<IContactFormCityProps> = ({ postalCode, render }) => {
    const [options, setOptions] = React.useState<Array<IComboBoxOption>>([])

    const postalinfoDetails = usePostinfoDetail(postalCode)

    React.useEffect(() => {
        if (postalinfoDetails.isSuccess) {
            const cityOptions = postalinfoDetails.data.postnamen.map(obj => ({ key: postalinfoDetails.data.identificator.objectId, text: obj.geografischeNaam.spelling }))

            setOptions(cityOptions)
        }
    }, [postalinfoDetails.data, postalinfoDetails.isSuccess])

    return (
        <>
            {render(options)}
        </>
    )
}

export default ContactFormCity;