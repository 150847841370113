import * as React from 'react'
import useGalleryStyles from './Gallery.styles'
import { ImageGalleryComponent } from '../../ImageGallery'

interface GalleryProps { }

const Gallery: React.FC<GalleryProps> = () => {
  const classes = useGalleryStyles()

  return (
    <div className={`${classes.general}`}>
      <div className={classes.header}></div>
      <div className={`Contact ms-Grid`} dir="ltr">
        <div className={`ms-Grid-row`}>
          <div className={`ms-Grid-col ms-sm1`}></div>
          <div className={`ms-Grid-col ms-sm10`}>
            <ImageGalleryComponent />
          </div>
        </div>
      </div>
      <div className={classes.header}></div>
    </div>
  )
}

export default Gallery;
