import create from 'zustand'
import Axios from 'axios'

import { IConfigurationStore } from '../../types/store.types'
import { ApiService } from '../../services'

const useConfigurationStore = create<IConfigurationStore>((set, get) => ({
    apiService : new ApiService(Axios.create({
        baseURL: 'https://api.basisregisters.vlaanderen.be'
    }))
}))

export default useConfigurationStore