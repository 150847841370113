import * as React from 'react'
import useAudienceStyles from './Audience.styles'
import banner from './../../../images/pax&mila.jpeg'
import { FontIcon } from '@fluentui/react/lib/components/Icon'

interface AudienceProps { }

const Audience: React.FC<AudienceProps> = () => {
  const classes = useAudienceStyles()

  return (
    <div className={`ms-Grid ${classes.general}`} dir="ltr">
      <div className={`ms-Grid-row`}>
        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6`} style={{ padding: '0' }}>
          <img className={classes.image} src={banner} />
        </div>
        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6`} style={{ padding: '0' }}>
          <div className={`Audience ${classes.info}`}>
            <div className={classes.header}>
              doelgroep
            </div>
            <div className={`Contact ms-Grid`} dir="ltr">
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`} style={{ textAlign: 'center' }}>
                  <FontIcon iconName='sizes' className={classes.fontIcon} />
                </div>
              </div>
            </div>
            <div className={`Contact ms-Grid`} dir="ltr">
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  voor groot en klein
                </div>
              </div>
            </div>
            <div className={`Contact ms-Grid`} dir="ltr">
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`} style={{ textAlign: 'center' }}>
                  de <b>focus</b> leggen we graag bij de <u>grotere honden</u><br/>
                  uiteraard is uw kleine pluizebolletje ook altijd welkom!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Audience;
