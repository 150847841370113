import React from 'react'
import { registerIcons } from '@fluentui/react/lib/Styling'

registerIcons({
	icons: {
		'cost': (
			<svg viewBox="0 0 141.7 141.7">
				<g id="YmmXtS_00000147209826505199896270000014463196730799045008_">
					<g fill='#BBA14F'>
						<path d="M12.2,119.4c19.2,4.9,38.3,9.8,57.6,14.7c0.7-2.8,1.4-5.5,2.1-8.2c5.5-21.4,10.9-42.9,16.3-64.3c0.2-0.9,0.2-2-0.1-2.9
			c-3.4-9-7-17.9-10.5-26.9c-0.3-0.6-0.5-1.3-0.9-2c-1.4,1.1-2.7,2.2-3.9,3.3c-0.3,0.2-0.3,0.8-0.3,1.2c-0.3,4.2-3.6,7.2-7.7,7
			c-4.1-0.2-7.2-3.6-7.1-7.7c0.2-4.1,3.6-7.2,7.8-7.1c0.7,0,1.5-0.3,2-0.7c7.2-5.6,11.6-12.9,13.6-21.7C82,0.6,85.2-1,88,0.7
			c1.6,0.9,2.4,2.7,2,4.5c-1.1,6.2-3.6,11.8-7,16.9c-0.5,0.8-0.6,1.4-0.2,2.3c4.4,10.9,8.7,21.8,13,32.7c0.7,1.8,0.7,3.7,0.2,5.6
			c-6.1,24.1-12.3,48.1-18.4,72.2c-1.4,5.6-5.1,7.8-10.5,6.4c-18.8-4.8-37.5-9.6-56.3-14.4c-4.8-1.2-7.1-5.1-5.9-9.8
			c6.2-24.4,12.4-48.8,18.6-73.2c0.5-2,1.5-3.6,3.1-4.8c9.9-7.9,19.7-15.9,29.6-23.7c3.8-3,8.2-4.1,13-3.4c0.2,0,0.4,0.1,0.6,0.1
			c0.1,0,0.3,0.1,0.7,0.4c-1.7,2.2-3.3,4.5-4.9,6.6c-0.2,0.2-0.5,0.4-0.7,0.5c-2.4,0.4-4.2,2-6.1,3.5c-8.8,7-17.5,14.1-26.3,21.1
			c-1,0.8-1.5,1.7-1.9,2.9c-6,23.7-12.1,47.4-18.1,71.2C12.3,118.6,12.2,118.9,12.2,119.4z"/>
						<path d="M90.6,27.6c0.8-2.1,1.6-4.3,2.5-6.7c1.6,1.3,3.1,2.4,4.6,3.7c7.4,6.6,14.8,13.3,22.1,19.9c1.4,1.2,2.2,2.7,2.6,4.6
			c4.8,22.6,9.7,45.1,14.6,67.7c0.9,4.3-1.4,7.9-5.7,8.9c-15.4,3.3-30.7,6.6-46.1,9.9c-0.3,0.1-0.6,0.1-1.2,0.1
			c0.8-2.5,1.5-4.9,2.3-7.2c0.1-0.3,0.7-0.5,1.1-0.6c13.5-2.9,26.9-5.8,40.4-8.7c0.8-0.2,1.5-0.4,2.4-0.6c-1-4.9-2-9.6-3.1-14.3
			c-3.8-17.5-7.5-35-11.4-52.5c-0.2-0.9-0.8-2-1.5-2.6C106.5,41.9,98.6,34.8,90.6,27.6z"/>
						<path d="M60.7,63.4c1.2,0.6,2.4,1.2,3.5,1.8c3.8,2.2,5.8,5.6,6.3,9.9c0.1,1.3-0.4,2.9-1.1,4c-0.9,1.4-2.6,1.6-4.2,1
			c-1.6-0.6-2.6-1.9-2.6-3.8c0-2.5-0.8-4.5-2.7-6.1c-0.2-0.2-0.5-0.3-0.9-0.5c-1.1,4.3-2.2,8.4-3.4,12.6c1.5,0.9,2.9,1.8,4.3,2.8
			c5.5,3.6,7.7,8.8,6,14.7c-1.6,6-6.8,10.1-13.1,10.3c-1.1,0-2.3-0.1-3.4-0.2c-0.8-0.1-1.2,0.1-1.4,0.9c-0.3,1.3-0.7,2.5-1.1,3.7
			c-0.3,0.9-1,1.5-2.1,1.2c-1.1-0.3-1.7-1-1.5-2.1c0.3-1.6,0.7-3.1,1-4.8c-1-0.5-2-0.9-2.9-1.5c-5.2-3.1-8.4-7.4-8.1-13.7
			c0.2-3.3,2.7-5.2,5.6-4.2c1.7,0.6,2.6,1.8,2.7,3.7c0.1,1.6,0.3,3.2,0.6,4.7c0.5,2,1.8,3.4,3.8,4.5c1.3-4.7,2.5-9.4,3.8-14
			c-1.8-1.3-3.6-2.5-5.3-3.8c-4.5-3.6-5.8-8.2-4-13.8c1.6-5.1,5.7-8.2,11.2-8.5c1.6-0.1,3.2,0,5,0c0.3-0.9,0.6-2.1,0.9-3.2
			c0.5-1.5,1.3-2.1,2.5-1.8c1.2,0.3,1.7,1.2,1.4,2.7C61.3,61.1,61,62.3,60.7,63.4z M49.9,103.7c4.1,0.4,7.6-1.8,8.3-5.3
			c0.7-3.2-1.3-6.5-4.9-7.8C52.3,94.9,51.1,99.2,49.9,103.7z M55.1,68.7c-3.4-0.4-5.9,1-6.8,3.7c-1,3.2,0.2,5.7,3.8,7.6
			C53.1,76.2,54.1,72.5,55.1,68.7z"/>
						<path d="M101.2,102.9c-0.6-3-1.2-5.7-1.7-8.5c-0.2-0.8-0.3-1.6-0.5-2.3c-0.4-2.2-0.4-2.3-2.9-2.6c1-4.3,2.1-8.7,3.1-13
			c0.1,0,0.2,0,0.3,0c0.5,2.1,0.9,4.1,1.4,6.2c1.8,0.2,3.6,0.3,5.3,0.5c5.5,0.8,9.2,4.1,10.3,9c1.2,5.3-0.6,10.4-5.1,13.5
			c-1.5,1.1-3.3,1.8-5.1,2.7c0.2,1.3,0.5,2.6,0.7,4c0.1,1-0.1,1.9-1.3,2.1c-1.2,0.2-1.8-0.4-2.1-1.4c-0.3-1.3-0.6-2.6-0.9-3.9
			c-2-0.2-3.9-0.2-5.8-0.5c-4.7-0.9-4.9-1.3-3.8-5.8c0.4-1.5,0.7-3.1,1.2-5C95.8,100.7,97.6,102.8,101.2,102.9z M104.9,102.4
			c3.8-1.5,5.5-4.6,4.6-7.9c-0.7-2.8-3.8-4.6-7.1-4.2C103.2,94.3,104,98.3,104.9,102.4z"/>
						<path d="M100.8,71.4c0.2-0.7,0.4-1.9,0.7-3.1c0.2-0.8,0.4-1.6,0.7-2.4c3.7,0,8.8,4.2,9.4,7.6c0.3,1.8-0.6,3.6-2.3,4.3
			c-1.9,0.7-3.6,0.1-4.8-1.6C103.4,74.6,102.2,73.3,100.8,71.4z"/>
					</g>
				</g>
			</svg>
		)
	}
})

export default registerIcons