import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useGalleryStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.themeSecondary}`
    },
    image: {
        width: '100%'
    },
    info: {
        padding: '20px',
        color: `${theme.palette.themeSecondary}`
    },
    header: {
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 0 15px',
        color: `${theme.palette.neutralPrimary}`
    }
}))

export default useGalleryStyles