import * as React from 'react'

import { FontIcon } from '@fluentui/react/lib/components/Icon'
import { Stack } from '@fluentui/react/lib/components/Stack'

import useServiceStyles from './Service.styles'

interface ServiceProps { }

const Service: React.FC<ServiceProps> = () => {
  const classes = useServiceStyles()

  return (
    <div className={`${classes.general}`}>
      <div className={classes.header}>
        diensten
      </div>
      <div className={`ms-Grid`} dir="ltr">
        <div className={`ms-Grid-row`}>
          <Stack
            horizontal
            wrap
          >
            <div className={`ms-Grid-col ms-sm12 ms-md6 ms-xl3`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`}>
                  <FontIcon iconName='generalCare' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  algemene verzorging
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  oren & ogen reinigen, <br /> voetzolen ontharen, <br />  nagelverzorging
                </div>
              </div>
            </div>
            <div className={`ms-Grid-col ms-sm12 ms-md6 ms-xl3`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`}>
                  <FontIcon iconName='washing' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  wassen & drogen
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  wassen & drogen
                </div>
              </div>
            </div>
            <div className={`ms-Grid-col ms-sm12 ms-md6 ms-xl3`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`}>
                  <FontIcon iconName='trimming' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  toiletteren
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  ontwollen, plukken, <br /> knippen of scheren
                </div>
              </div>
            </div>
            <div className={`ms-Grid-col ms-sm12 ms-md6 ms-xl3`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`}>
                  <FontIcon iconName='finish' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  afwerking
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  klaar voor een avondje stappen
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default Service;
