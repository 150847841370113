import * as React from 'react'

import { TextField } from '@fluentui/react/lib/TextField'
import { DetailsList, SelectionMode } from '@fluentui/react/lib/DetailsList'

import { IPriceListItem } from '../../types/priceListItems'
import usePricingListStyles from './PricingList.styles'

interface PricingListProps { }

const PricingList: React.FC<PricingListProps> = () => {
  const classes = usePricingListStyles()

  const columns = [
    { key: 'name', name: 'ras', fieldName: 'name', minWidth: 75, maxWidth: 200 },
    { key: 'value', name: 'prijs', fieldName: 'value', minWidth: 45, maxWidth: 50 },
  ]

  const allItems: Array<IPriceListItem> = [
    {
      name: 'Affenpincher',
      value: '€ 55,00'
    },
    {
      name: 'Afghaanse windhond',
      value: '€ 100,00'
    },
    {
      name: 'Airedaleterriër',
      value: '€ 100,00'
    },
    {
      name: 'Akita inu',
      value: '€ 100,00'
    },
    {
      name: 'Alaskan malamute',
      value: '€ 100,00'
    },
    {
      name: 'Amerikaanse bulldog',
      value: '€ 55,00'
    },
    {
      name: 'Amerikaanse bullterriër',
      value: '€ 55,00'
    },
    {
      name: 'Amerikaanse Staffordshire terriër',
      value: '€ 60,00'
    },
    {
      name: 'Amerikaanse cocker',
      value: '€ 85,00'
    },
    {
      name: 'Anatolische herder',
      value: '€ 80,00'
    },
    {
      name: 'Argentijnse dog',
      value: '€ 80,00'
    },
    {
      name: 'Australische terriër',
      value: '€ 60,00'
    },
    {
      name: 'Barbet',
      value: '€ 120,00'
    },
    {
      name: 'Barzoi',
      value: '€ 100,00'
    },
    {
      name: 'Basenji',
      value: '€ 65,00'
    },
    {
      name: 'Basset hound',
      value: '€ 65,00'
    },
    {
      name: 'Basset fauve de Bretagne',
      value: '€ 85,00'
    },
    {
      name: 'Beagle',
      value: '€ 55,00'
    },
    {
      name: 'Bearded Collie',
      value: '€ 100,00'
    },
    {
      name: 'Beauceron',
      value: '€ 80,00'
    },
    {
      name: 'Bedlington terriër',
      value: '€ 75,00'
    },
    {
      name: 'Berner sennen',
      value: '€ 110,00'
    },
    {
      name: 'Bichon frisé',
      value: '€ 65,00'
    },
    {
      name: 'Bloedhond',
      value: '€ 70,00'
    },
    {
      name: 'Bobtail',
      value: '€ 110,00'
    },
    {
      name: 'Bordeaux dog',
      value: '€ 75,00'
    },
    {
      name: 'Border collie',
      value: '€ 80,00'
    },
    {
      name: 'Borderterriër',
      value: '€ 65,00'
    },
    {
      name: 'Boston terriër',
      value: '€ 50,00'
    },
    {
      name: 'Bouvier',
      value: '€ 90,00'
    },
    {
      name: 'Boxer',
      value: '€ 65,00'
    },
    {
      name: 'Briard',
      value: '€ 120,00'
    },
    {
      name: 'Bull terriër',
      value: '€ 65,00'
    },
    {
      name: 'Bullmastiff',
      value: '€ 70,00'
    },
    {
      name: 'Bulldog – Engels',
      value: '€ 60,00'
    },
    {
      name: 'Bulldog – Frans',
      value: '€ 50,00'
    },
    {
      name: 'CairnTerriër',
      value: '€ 60,00'
    },
    {
      name: 'Cavelier King Charles',
      value: '€ 60,00'
    },
    {
      name: 'Chihuahua korthaar',
      value: '€ 40,00'
    },
    {
      name: 'Chihuahua langhaar',
      value: '€ 45,00'
    },
    {
      name: 'Chinese naakthond',
      value: '€ 40,00'
    },
    {
      name: 'Chow Chow',
      value: '€ 100,00'
    },
    {
      name: 'Clumber spaniel',
      value: '€ 75,00'
    },
    {
      name: 'Cocker spaniel',
      value: '€ 75,00'
    },
    {
      name: 'Coton de tuléar',
      value: '€ 55,00'
    },
    {
      name: 'Curly coated retriever',
      value: '€ 80,00'
    },
    {
      name: 'Dalmatiër',
      value: '€ 60,00'
    },
    {
      name: 'Deerhound',
      value: '€ 100,00'
    },
    {
      name: 'Dobermann',
      value: '€ 65,00'
    },
    {
      name: 'Drentsche patrijshond',
      value: '€ 70,00'
    },
    {
      name: 'Duitse dog',
      value: '€ 85,00'
    },
    {
      name: 'Duitse herder korthaar',
      value: '€ 75,00'
    },
    {
      name: 'Duitse herder langhaar',
      value: '€ 80,00'
    },
    {
      name: 'Duitse jacht terriër',
      value: '€ 60,00'
    },
    {
      name: 'Duitse staande hond – glad',
      value: '€ 65,00'
    },
    {
      name: 'Duitse staande hond – ruw',
      value: '€ 95,00'
    },
    {
      name: 'Dwergpincher',
      value: '€ 50,00'
    },
    {
      name: 'Dwergkees',
      value: '€ 45,00'
    },
    {
      name: 'Engelse cocker',
      value: '€ 80,00'
    },
    {
      name: 'Engelse setter',
      value: '€ 75,00'
    },
    {
      name: 'Engelse springer spaniël',
      value: '€ 70,00'
    },
    {
      name: 'Epagneul',
      value: '€ 50,00'
    },
    {
      name: 'Finse spits',
      value: '€ 75,00'
    },
    {
      name: 'Flatcoated retriever',
      value: '€ 75,00'
    },
    {
      name: 'Foxterriër – ruw',
      value: '€ 90,00'
    },
    {
      name: 'Foxterriër – glad',
      value: '€ 50,00'
    },
    {
      name: 'Friese stabij',
      value: '€ 80,00'
    },
    {
      name: 'Galgo',
      value: '€ 60,00'
    },
    {
      name: 'Golden retriever',
      value: '€ 75,00'
    },
    {
      name: 'Greyhound',
      value: '€ 55,00'
    },
    {
      name: 'Gordon setter',
      value: '€ 80,00'
    },
    {
      name: 'Griffon',
      value: '€ 60,00'
    },
    {
      name: 'Groenendaler',
      value: '€ 80,00'
    },
    {
      name: 'Grote Zwitserse sennenhond',
      value: '€ 85,00'
    },
    {
      name: 'Havanezer',
      value: '€ 55,00'
    },
    {
      name: 'Heidewachtel',
      value: '€ 70,00'
    },
    {
      name: 'Hollandse herder – kort',
      value: '€ 60,00'
    },
    {
      name: 'Hollandse herder – lang',
      value: '€ 75,00'
    },
    {
      name: 'Hollandse herder – ruw',
      value: '€ 100,00'
    },
    {
      name: 'Hovawart',
      value: '€ 100,00'
    },
    {
      name: 'Husky',
      value: '€ 85,00'
    },
    {
      name: 'Ierse setter',
      value: '€ 80,00'
    },
    {
      name: 'Ierse terriër',
      value: '€ 95,00'
    },
    {
      name: 'Ierse wolfshond',
      value: '€ 120,00'
    },
    {
      name: 'Italiaans windhondje',
      value: '€ 45,00'
    },
    {
      name: 'Jack Russel terriër – kort',
      value: '€ 50,00'
    },
    {
      name: 'Jack Russel terriër – ruw',
      value: '€ 70,00'
    },
    {
      name: 'Japanse spaniël',
      value: '€ 60,00'
    },
    {
      name: 'Keeshond',
      value: '€ 60,00'
    },
    {
      name: 'Kerry blue terriër',
      value: '€ 95,00'
    },
    {
      name: 'Kooikerhondje',
      value: '€ 60,00'
    },
    {
      name: 'Labrador retriever',
      value: '€ 80,00'
    },
    {
      name: 'Labradoodle – groot',
      value: '€ 110,00'
    },
    {
      name: 'Labradoodle – midden',
      value: '€ 90,00'
    },
    {
      name: 'Labradoodle – klein',
      value: '€ 75,00'
    },
    {
      name: 'Lakense herder',
      value: '€ 95,00'
    },
    {
      name: 'Landseer',
      value: '€ 130,00'
    },
    {
      name: 'Leonberger',
      value: '€ 130,00'
    },
    {
      name: 'Lhaso Apso',
      value: '€ 55,00'
    },
    {
      name: 'Maltezer',
      value: '€ 55,00'
    },
    {
      name: 'Mastiff',
      value: '€ 80,00'
    },
    {
      name: 'Mastino Napolitano',
      value: '€ 110,00'
    },
    {
      name: 'Mechelse herder',
      value: '€ 75,00'
    },
    {
      name: 'Mopshond',
      value: '€ 45,00'
    },
    {
      name: 'Munsterlander',
      value: '€ 75,00'
    },
    {
      name: 'Nachtvlinderhondje',
      value: '€ 45,00'
    },
    {
      name: 'Newfoundlander',
      value: '€ 130,00'
    },
    {
      name: 'Norfolk terriër',
      value: '€ 60,00'
    },
    {
      name: 'Norwich terriër',
      value: '€ 60,00'
    },
    {
      name: 'Nova Scotia duck tolling retriever',
      value: '€ 75,00'
    },
    {
      name: 'Otterhound',
      value: '€ 90,00'
    },
    {
      name: 'Pekinees',
      value: '€ 65,00'
    },
    {
      name: 'Poedel – toy',
      value: '€ 65,00'
    },
    {
      name: 'Poedel – dwerg',
      value: '€ 70,00'
    },
    {
      name: 'Poedel – middenslag',
      value: '€ 75,00'
    },
    {
      name: 'Konigspoedel',
      value: '€ 90,00'
    },
    {
      name: 'Pomeriaan',
      value: '€ 50,00'
    },
    {
      name: 'Pointer',
      value: '€ 70,00'
    },
    {
      name: 'Powderpuff',
      value: '€ 60,00'
    },
    {
      name: 'Pyreneese berghond',
      value: '€ 130,00'
    },
    {
      name: 'Pyreneese hersershond',
      value: '€ 95,00'
    },
    {
      name: 'Rhodesië ridgeback',
      value: '€ 65,00'
    },
    {
      name: 'Rottweiler',
      value: '€ 70,00'
    },
    {
      name: 'Saarloos wolfshond',
      value: '€ 100,00'
    },
    {
      name: 'Saluki',
      value: '€ 70,00'
    },
    {
      name: 'Samojeed',
      value: '€ 70,00'
    },
    {
      name: 'Schapendoes',
      value: '€ 85,00'
    },
    {
      name: 'Schipperke',
      value: '€ 60,00'
    },
    {
      name: 'Schotse collie',
      value: '€ 85,00'
    },
    {
      name: 'Schotse terriër',
      value: '€ 80,00'
    },
    {
      name: 'Shar pei',
      value: '€ 65,00'
    },
    {
      name: 'Sheltie',
      value: '€ 70,00'
    },
    {
      name: 'Shiba inu',
      value: '€ 70,00'
    },
    {
      name: 'Shih-tzu',
      value: '€ 60,00'
    },
    {
      name: 'Schnauzer – dwerg',
      value: '€ 65,00'
    },
    {
      name: 'Schnauzer – middel',
      value: '€ 80,00'
    },
    {
      name: 'Schnauzer – riezen',
      value: '€ 90,00'
    },
    {
      name: 'Siberische husky',
      value: '€ 90,00'
    },
    {
      name: 'Sint Bernard',
      value: '€ 130,00'
    },
    {
      name: 'Sky terriër',
      value: '€ 65,00'
    },
    {
      name: 'Soft coated wheaten terriër',
      value: '€ 80,00'
    },
    {
      name: 'Spaanse waterhond',
      value: '€ 110,00'
    },
    {
      name: 'Staffordshire bull terriër',
      value: '€ 55,00'
    },
    {
      name: 'Sussex spaniël',
      value: '€ 80,00'
    },
    {
      name: 'Teckel – kort',
      value: '€ 50,00'
    },
    {
      name: 'Teckel – lang',
      value: '€ 60,00'
    },
    {
      name: 'Teckel – ruw',
      value: '€ 70,00'
    },
    {
      name: 'Tervurense herder',
      value: '€ 75,00'
    },
    {
      name: 'Tibetaanse Mastiff',
      value: '€ 120,00'
    },
    {
      name: 'Tibetaanse spaniël',
      value: '€ 60,00'
    },
    {
      name: 'Tibetaanse terriër',
      value: '€ 75,00'
    },
    {
      name: 'Vizsla – glad',
      value: '€ 65,00'
    },
    {
      name: 'Vizsla – ruw',
      value: '€ 95,00'
    },
    {
      name: 'Vlinderhondje',
      value: '€ 55,00'
    },
    {
      name: 'Weimaraner – kort',
      value: '€ 70,00'
    },
    {
      name: 'Weimaraner – ruw',
      value: '€ 85,00'
    },
    {
      name: 'Welsh corgi',
      value: '€ 55,00'
    },
    {
      name: 'Welsh springer spaniël',
      value: '€ 75,00'
    },
    {
      name: 'Welsh terriër',
      value: '€ 90,00'
    },
    {
      name: 'West Highland white terriër',
      value: '€ 75,00'
    },
    {
      name: 'Whippet',
      value: '€ 45,00'
    },
    {
      name: 'Wolfskees',
      value: '€ 75,00'
    },
    {
      name: 'Yorkshire terriër',
      value: '€ 60,00'
    },
    {
      name: 'Zweetse lappenhond',
      value: '€ 75,00'
    },
    {
      name: 'Zwitserse herder',
      value: '€ 70,00'
    }

  ]

  const [items, setItems] = React.useState<Array<IPriceListItem>>(allItems)

  const onFilter = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    setItems(
      newValue ? allItems.filter(i => i.name.toLowerCase().indexOf(newValue) > -1) : allItems
    )
  }

  return (
    <>
      <br />
      <TextField
        label='Filter op naam'
        onChange={onFilter}
      />
      <DetailsList
        compact
        columns={columns}
        items={items}
        selectionMode={SelectionMode.none}
      />
    </>
  )
}

export default PricingList;
