import React from 'react'
import { registerIcons } from '@fluentui/react/lib/Styling'

registerIcons({
    icons: {
        'generalCare': (
            <svg viewBox="0 0 141.7 141.7">
                <g fill='#F2F7FA'>
                    <path d="M33.9,141.7c-8.8-2.3-16-15.2-15.9-23.4c0-5.1,2.6-9,6.5-12.2c4.2-3.4,9.1-5.6,14-7.9c4.2-1.9,7.6-4.6,10.5-8.4
		c3.8-5,7.8-9.9,12.1-14.6c7.8-8.4,13.3-8.2,20.6,0.6c3.8,4.5,7.1,9.3,9.9,14.4c2.4,4.2,5.7,6.9,9.8,9c3.8,2,7.6,3.9,11.1,6.4
		c8.2,5.8,10.4,13.5,6.5,22.8c-2.4,5.7-5.7,10.7-12.2,13c-2.9,0.1-5.5,0.1-8.7,0c-7.5-1.9-13.8-5.2-20.3-8.1
		c-6.1-2.7-12.1-3-18.2-0.1c-6,2.9-11.7,6.3-18.1,8.3C39.1,141.7,36.7,141.7,33.9,141.7z"/>
                    <path d="M105.6,45.3c-0.9,6.1-3,11.3-6.7,15.9c-6.7,8.2-16.7,7-21.2-2.6c-5-10.7-2.7-26.9,5-35.3c0.2-0.3,0.5-0.6,0.8-0.8
		c6.9-3.2,7.8-9.8,8.9-16.2c0.4-2.2,1-4.2,1.9-6.3c1.5,4.5,1.1,9.1,1.1,13.6c0,3.3,0.9,5.5,3.6,7.8
		C106.1,27.6,106.9,36.1,105.6,45.3z"/>
                    <path d="M55.5,66.5c-5.3,0.5-9.2-1.8-11.8-5.8c-7.1-10.8-8-22.4-2.9-34.3c0.9-2.2,2.4-4.3,4.4-5.5c2.6-1.7,3.4-3.9,3.3-6.9
		c-0.2-4.6,0.3-9.2,1.2-13.8c2.1,4.6,2.4,9.7,3.4,14.6c0.5,2.4,1.3,4,3.6,5.2c4.9,2.5,7.9,6.8,9.7,11.9c2.9,8.4,3,16.8-0.3,25.1
		C64.1,61.6,61.1,65.4,55.5,66.5z"/>
                    <path d="M108,60.1c3.5-4,7.4-7.2,12.3-8.8c1.2-0.4,2.6-0.5,3.8-0.7c2-0.3,4.1,1.3,6.1-0.6c2.7-2.4,4.9-5.1,6.3-8.5
		c0.7-1.6,0.3-3.5,1.8-5.3c1.4,3.2,1,6.1,0.6,8.9c-0.6,3.8-1.8,7.4-4,10.6c-0.7,1-1.1,2-1,3.3c0.3,11.4-5.2,19.7-14.4,25.8
		c-2.1,1.4-4.5,2.1-7.1,2.2c-8.8,0.1-13.5-5.7-11.3-14.2C102.2,68,104.7,63.9,108,60.1z"/>
                    <path d="M8.8,67.5c-0.8-3-1.6-5.8-1-8.7c0.3-1.5-0.6-2.5-1.3-3.6c-3.5-5.7-4.8-11.8-3.7-18.4c1.7,0,1.4,1.2,1.6,2
		c0.7,3.6,2.4,6.7,4.9,9.3c1.8,1.9,3.6,3.3,6.8,2.6c4-0.8,7.6,1.2,10.9,3.5c6.2,4.3,10.6,9.9,13,17c1.5,4.6,1.9,9.3-2.2,12.9
		c-4.1,3.6-9,3.5-13.8,1.6c-5.4-2.2-9.1-6.4-12.1-11.3C10.8,72.4,9.6,70.2,8.8,67.5z"/>
                </g>
            </svg>
        ),
        'washing': (
            <svg viewBox="0 0 141.7 141.7">
                <g fill='#F2F7FA'>
                    <path d="M141.7,22.6c-2.2,0.1-4.5,0.1-6.7,0.1c-4,0-8,0.3-11.9,0.8c-5.3,0.7-10,2.9-13.9,6.7c-2.2,2.1-4.4,4.1-6.5,6.3
		c-0.5,0.5-0.5,0.9,0,1.4c2,2,4,3.9,5.4,6.3c2.1,3.4,3.1,7,3.2,10.9c0.1,5-1.4,9.4-4.3,13.4c-0.6,0.8-1.2,1.5-1.9,2.1
		c-0.5-0.1-0.7-0.5-0.9-0.7C92.5,58.1,80.8,46.4,69,34.6c-3-3-6-6-9.1-9c-0.6-0.6-0.6-0.9,0-1.5c4.1-3.6,8.7-5.5,14.2-5.5
		c6.2-0.1,11.4,2.1,15.7,6.5c1,1,2,2,3,3c0.5,0.6,0.9,0.5,1.5,0c3-2.9,5.9-5.9,9.1-8.6c3.8-3.2,8.1-5.5,12.9-6.7
		c3.7-0.9,7.6-1.3,11.4-1.6c4.5-0.4,9-0.2,13.5-0.3c0.1,0,0.2,0,0.4,0c0.1,0.2,0.1,0.4,0.1,0.8c-0.2,2.3-0.1,4.4-0.1,6.5
		c0,1-0.1,2.1,0.1,3.1C141.7,21.6,141.7,22.1,141.7,22.6z"/>
                    <path d="M104.3,94.4c-0.7,0.4-0.9-0.2-1.2-0.5C97.3,88,91.4,82.1,85.5,76.3C69.1,59.8,52.7,43.4,36.3,27c-1-1-1-1,0-2
		c2.6-2.7,5.3-5.3,7.9-8c0.2-0.2,0.4-0.5,0.6-0.5c22.8,22.9,45.7,45.7,68.6,68.6C110.5,88.1,107.4,91.2,104.3,94.4z"/>
                    <path d="M78.1,88.1c1.3-1.9,2.8-3.5,4.2-5.1c1-1.1,2.4-1.2,3.4-0.3c1.1,0.9,1.4,2.3,0.5,3.5c-1.3,1.6-2.6,3.2-4,4.8
		c-0.8,0.9-2.3,1-3.2,0.4C78.1,90.7,77.7,89.5,78.1,88.1z"/>
                    <path d="M87.1,94.4c0.8-1.1,1.5-2.2,2.3-3.3c1-1.3,2.4-1.5,3.7-0.7c1.1,0.8,1.3,2.4,0.5,3.7c-1.1,1.6-2.1,3.1-3.2,4.7
		c-0.9,1.2-2.4,1.5-3.6,0.6c-1.1-0.8-1.4-2.3-0.7-3.5C86.4,95.4,86.7,95,87.1,94.4z"/>
                    <path d="M18,67c-0.1,0.8-0.6,1.4-1.2,1.9c-1.3,1.1-2.6,2.2-3.9,3.3c-1.5,1.3-3.2,1-4.1-0.5c-0.5-0.9-0.4-2.2,0.4-3
		c1.5-1.4,3.2-2.8,4.8-4.1c0.9-0.7,1.8-0.7,2.7-0.2C17.7,65,18,65.9,18,67z"/>
                    <path d="M58.8,114.8c0.5-0.9,1-1.7,1.5-2.5c0.8-1.3,2.4-1.8,3.6-1c1.2,0.7,1.6,2.2,0.8,3.6c-0.9,1.6-1.9,3.2-2.9,4.8
		c-0.9,1.4-2.2,1.7-3.6,1c-1.1-0.6-1.6-2.1-0.9-3.5C57.8,116.4,58.3,115.7,58.8,114.8z"/>
                    <path d="M47.2,47.9c-0.9,1.1-2.2,1.5-3.2,2.2c-0.9,0.6-1.9,1.1-2.9,1.6c-1.2,0.6-2.6,0.1-3.2-0.9c-0.7-1.2-0.5-2.6,0.6-3.4
		c1.8-1.2,3.6-2.2,5.4-3.3c1.1-0.6,2-0.4,2.9,0.4c0.8,0.8,1,1.7,0.6,2.8C47.4,47.5,47.4,47.7,47.2,47.9z"/>
                    <path d="M2.2,57.6c1.3-0.9,2.5-1.8,3.7-2.6c1.3-0.8,2.8-0.6,3.6,0.7c0.7,1.1,0.4,2.8-0.7,3.5c-1.5,1-3,2.1-4.5,3.2
		c-1.3,0.9-2.9,0.8-3.8-0.4c-0.9-1.2-0.7-2.5,0.6-3.6C1.4,58,1.8,57.8,2.2,57.6z"/>
                    <path d="M67.5,128.7c-0.1-0.6,0-1.1,0.3-1.6c0.8-1.7,1.6-3.4,2.5-5.1c0.7-1.5,2.1-1.8,3.5-1.2c1.3,0.5,1.7,2.1,1,3.4
		c-0.8,1.7-1.6,3.5-2.5,5.2c-0.6,1.1-1.5,1.6-2.6,1.4C68.5,130.7,67.8,130,67.5,128.7z"/>
                    <path d="M23.4,45.7c1.2,0.2,1.9,0.8,2.1,1.8c0.2,1.1,0,2.2-1.1,2.9c-1.6,1-3.2,2-4.9,2.9c-1.4,0.8-2.9,0.5-3.6-0.7
		c-0.8-1.4-0.4-2.7,1.1-3.7c1.5-0.9,3.1-1.9,4.6-2.8C22.1,45.7,22.7,45.6,23.4,45.7z"/>
                    <path d="M31.6,40.2c1.6-1.2,3.4-1.8,5.1-2.7c0.7-0.4,1.3-0.8,2.1-0.7c1.1,0.1,2.1,0.7,2.4,1.7c0.4,1.1,0,2.4-1,2.9
		c-1.9,1-3.8,2-5.7,2.9c-1.1,0.5-2.3,0.1-3-0.7C30.7,42.5,30.8,41.3,31.6,40.2z"/>
                    <path d="M70.8,106.3c-1.3,0.3-2.3,0-2.9-1.1c-0.6-1-0.5-2.1,0.2-3c1.1-1.5,2.1-3,3.2-4.5c1.1-1.4,2.4-1.6,3.7-0.6
		c1.1,0.8,1.3,2.4,0.4,3.7c-1,1.4-1.9,2.8-2.9,4.1C72.1,105.5,71.7,106.1,70.8,106.3z"/>
                    <path d="M82.5,105.3c2,0.6,2.6,2.1,1.7,3.8c-1,1.7-2,3.4-3,5.1c-0.6,1-2.1,1.4-3.2,0.9c-1.2-0.6-1.8-1.9-1.3-3.1
		c0.9-2,2.1-3.9,3.3-5.7C80.6,105.5,81.5,105.2,82.5,105.3z"/>
                    <path d="M77.5,74.7c1.7,0.9,2,2.8,0.7,4.1c-1.3,1.4-2.6,2.7-3.9,4c-1.1,1.1-2.6,1.2-3.6,0.2c-1.1-1-1.2-2.4-0.1-3.6
		c1.4-1.5,2.8-3,4.3-4.4c0.2-0.2,0.5-0.3,0.7-0.4C76.2,74.4,76.8,74.4,77.5,74.7z"/>
                    <path d="M25.1,57.1c1.2-0.9,2.4-1.8,3.6-2.6c1.2-0.8,2.8-0.6,3.6,0.5c0.8,1.1,0.5,2.7-0.6,3.6c-1.5,1.1-3,2.3-4.6,3.3
		c-1.2,0.8-2.8,0.6-3.6-0.6c-0.8-1.1-0.6-2.7,0.6-3.6C24.4,57.6,24.8,57.4,25.1,57.1z"/>
                    <path d="M26.9,77.6c-1.5,1.4-3,2.9-4.4,4.2c-1.1,1-2.4,1-3.5,0.1c-1-0.9-1.2-2.6-0.2-3.5c1.5-1.5,3.1-3,4.7-4.4
		c0.8-0.7,2.3-0.5,3.1,0.2C27.4,74.8,27.8,76.4,26.9,77.6z"/>
                    <path d="M55,52.5c0.9,1.9,0.6,2.8-1,3.9c-1.3,0.9-2.7,1.9-4,2.8c-1.4,1-3,0.8-3.9-0.6c-0.8-1.2-0.4-2.7,1-3.6
		c1.4-1,2.8-1.9,4.2-2.9C52.7,51.2,53.7,51.3,55,52.5z"/>
                    <path d="M66.2,92.9c-1.1,1.3-2.1,2.6-3.2,3.9c-1,1.1-2.3,1.2-3.4,0.4c-1-0.8-1.3-2.4-0.4-3.5c1.2-1.6,2.5-3.2,3.8-4.7
		c0.9-1,2.4-1.1,3.5-0.2c1.1,0.9,1.2,2.3,0.4,3.4C66.7,92.4,66.5,92.6,66.2,92.9z"/>
                    <path d="M51.4,110.9c-1,0.6-2,0.7-2.9,0.1c-0.8-0.5-1.2-1.3-1.2-2.3c0-0.6,0.3-1.1,0.6-1.5c1.1-1.5,2.1-3.1,3.3-4.6
		c0.8-1.1,2.2-1.3,3.3-0.6c1.1,0.7,1.7,2.3,0.9,3.4C54.1,107.2,53,109.2,51.4,110.9z"/>
                    <path d="M36.7,70.2c-1,1-2.2,1.5-3.5,0.8c-1.6-0.9-1.8-2.9-0.5-4.1c1.5-1.3,3-2.5,4.6-3.7c1.2-0.9,2.7-0.7,3.6,0.4
		c0.9,1.1,0.7,2.6-0.5,3.6C39.2,68.1,38,69.1,36.7,70.2z"/>
                    <path d="M38,97.9c1.3-1.6,2.5-3.1,3.7-4.6c0.9-1.1,2.6-1.4,3.6-0.5c1.1,0.9,1.4,2.2,0.4,3.6c-1.1,1.4-2.3,2.8-3.4,4.2
		c-1,1.2-2.3,1.6-3.5,0.9C37.6,100.7,37.3,99.5,38,97.9z"/>
                    <path d="M42.2,79.7c-1.7-1.2-1.9-2.8-0.5-4.2c1.3-1.3,2.7-2.5,4-3.8c0.9-0.8,2-1,2.9-0.5c1.1,0.6,1.6,1.4,1.5,2.6
		c-0.1,0.6-0.4,1.2-0.8,1.6c-1.4,1.3-2.8,2.6-4.3,3.9C44.2,80.1,43.2,80.3,42.2,79.7z"/>
                    <path d="M53.5,81.3c0.4-0.4,0.7-0.8,1.1-1.1c1-0.9,2.2-0.9,3.2,0c1,0.9,1.2,2.3,0.4,3.3c-1.4,1.7-2.9,3.3-4.5,4.8
		c-0.9,0.8-2.3,0.7-3.3-0.2c-0.8-0.8-1-2.4-0.2-3.4C51.2,83.5,52.4,82.4,53.5,81.3z"/>
                    <path d="M32.4,91.3c-1.4,1.5-3.4,1-4.1-0.3c-0.5-0.9-0.4-2.2,0.3-3c1.2-1.4,2.4-2.7,3.6-4.1c1.1-1.3,2.7-1.4,3.8-0.4
		c1.1,1,1.1,2.6-0.1,3.8C34.8,88.7,33.6,89.9,32.4,91.3z"/>
                    <path d="M66.3,68.1c1.3-1.2,2.4-1.6,3.5-1c1.4,0.8,1.8,2.8,0.6,4c-1.4,1.4-2.9,2.8-4.4,4.1c-1,0.9-2.6,0.7-3.4-0.2
		c-0.9-1-0.9-2.6,0.1-3.6C63.8,70.3,65,69.3,66.3,68.1z"/>
                    <path d="M63.2,62.5c-0.4,1-1.2,1.4-1.8,2c-1.2,1-2.3,1.9-3.5,2.8c-1.2,1-2.7,0.8-3.6-0.4c-0.8-1.1-0.6-2.7,0.5-3.6
		c1.3-1.1,2.7-2.1,4-3.2c1.1-0.9,2.3-1.1,3.2-0.6C63.2,60.2,63.5,61.2,63.2,62.5z"/>
                </g>
            </svg>
        ),
        'trimming': (
            <svg viewBox="0 0 141.7 141.7" >
                <g fill='#F2F7FA'>
                    <path d="M15.9,118.1c0.4-1.7,0.7-3.3,1.3-4.9c2.7-8.1,7.8-14.1,15.7-17.4c5.2-2.1,10.4-2.3,15.6,0c1,0.4,1.4,0.2,1.9-0.6
		c3.9-6.9,7.8-13.8,11.8-20.6c0.3-0.6,0.2-1,0-1.6c-6.4-12.6-12.7-25.3-19.1-37.9c-3-6-6.1-12-9.3-17.9c-2.3-4.1-2-8.1-0.2-12.1
		c0.8-1.8,1.8-3.5,3.3-5.1c11.3,19.8,22.6,39.4,33.8,59c0.1,0,0.3,0,0.4,0.1C82.3,39.4,93.6,19.8,104.9,0c1.2,1.3,2,2.5,2.6,3.8
		c1.1,2.2,1.9,4.4,2.1,6.8c0.2,2.1-0.5,4.1-1.4,5.9c-3.8,7.4-7.7,14.8-11.4,22.2C91,50.2,85.3,61.6,79.6,73.1
		c-0.3,0.7-0.3,1.2,0.1,1.8c3.9,6.9,7.9,13.7,11.8,20.6c0.4,0.7,0.8,0.9,1.6,0.5c5.4-2.5,10.8-2.3,16.1,0c9,3.9,14.1,11,16.2,20.4
		c0.1,0.6,0.2,1.3,0.4,2c0,2,0,4,0,6.2c-0.6,4.4-2.1,8.2-4.9,11.3c-2.9,3.3-6.6,5.1-10.9,5.9c-1.7,0-3.3,0-5.2,0
		c-4.3-0.7-8-2.2-11.3-4.8c-5.3-4.1-8.8-9.3-10.3-15.9c-1.4-6-2.1-12-4-17.9c-1.7-5.5-3.8-10.8-7.1-15.6c-0.3-0.5-0.6-1.1-1.3-1.3
		c-0.2,0.2-0.4,0.3-0.6,0.5c-0.3,0.3-0.5,0.6-0.8,1c-2,3.1-3.6,6.3-5,9.8c-2.6,6.7-4.2,13.6-5.2,20.6c-0.9,5.9-3.4,11.1-7.4,15.5
		c-4,4.4-9,7-14.8,8c-1.7,0-3.3,0-5.2,0c-6.4-1.1-10.9-4.5-13.7-10c-1.2-2.2-1.6-4.7-2.1-7.1C15.9,122.4,15.9,120.3,15.9,118.1z
		 M112,128.5c1.2-1.2,1.9-2.5,2.2-4.1c0.6-2.8,0.3-5.6-0.7-8.3c-1.7-4.4-4.5-7.9-9-9.7c-5.1-2.1-9.5,0.2-10.5,5.6
		c-0.8,3.8,0.1,7.4,2.1,10.7c2.1,3.6,5,6.4,9.2,7.5C107.7,130.9,110,130.5,112,128.5z M47,120.4c0.9-2.3,1.3-4.6,1.1-7.1
		c-0.2-2.5-1-4.8-3.1-6.4c-2.7-1.9-5.6-1.6-8.4-0.2c-6.2,3.1-9.9,9.7-9.2,16.6c0.3,2.7,1.4,5.1,4,6.4c2.3,1.2,4.7,0.8,7-0.2
		C42.5,127.8,45.2,124.6,47,120.4z"/>
                </g>
            </svg>

        ),
        'finish': (
            <svg viewBox="0 0 141.7 141.7">
                <g id="RLaDxj_00000141417121824280250380000001660672551175866046_">
                    <g fill='#F2F7FA'>
                        <path d="M0,71c1.4-7.1,2.5-14,4.3-20.8c0.7-2.6,2.5-5,4.2-7.2c2.3-2.9,5.6-3.2,9.1-2.7c9.4,1.4,17.6,5.8,25.8,10.2
			c3.2,1.7,6.2,3.6,9.3,5.4c2.5,1.5,3.4,3.4,2.4,6.4c-2,5.8-2,11.7,0,17.4c0.8,2.5,0.5,4.5-1.7,5.8C43.2,91.6,33,97.7,21.4,100.7
			c-9.3,2.4-14.2,0.3-17.1-8.9C2.2,85.1,1.4,78.1,0,71z"/>
                        <path d="M141.7,69.7c-0.1,7.3-0.6,15.4-3.8,23.1c-1.8,4.2-4,8.5-9.2,8.7c-3.6,0.1-7.6-0.3-10.8-1.7c-9.6-4.3-19-9.3-28.4-14.1
			c-2.2-1.1-2.9-3.1-2.1-5.7c2.1-6.1,2-12.2-0.1-18.3c-0.7-2.2-0.3-4,1.6-5.1c11-6.7,22.1-13.5,35-16.1c6.4-1.2,9.9,0.6,12.9,6.3
			C140.5,53.7,141.2,61.2,141.7,69.7z"/>
                        <path d="M84.7,71.1c0,9.2-3.2,12.4-12.4,12.6c-1.7,0-3.5,0-5.2-0.3c-6.7-1.2-8.7-3.4-9.1-10.3C57.2,61.8,60.8,58,72.1,58.1
			C81.6,58.3,84.7,61.4,84.7,71.1z"/>
                    </g>
                </g>
            </svg>
        )
    }
})

export default registerIcons