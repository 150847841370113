import * as React from 'react'

import { FontIcon } from '@fluentui/react/lib/components/Icon'
import { Stack } from '@fluentui/react/lib/components/Stack'

import useWorkStyles from './Work.styles'

interface WorkProps { }

const Work: React.FC<WorkProps> = () => {
  const classes = useWorkStyles()

  return (
    <div className={`${classes.general}`}>
      <div className={classes.header}>
        werkwijze
      </div>
      <div className={`Contact ms-Grid`} dir="ltr">
        <div className={`ms-Grid-row`}>
          <Stack
            horizontal
            wrap
          >
            <div className={`ms-Grid-col ms-sm12 ms-md4 ms-xl4`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`} style={{ textAlign: 'center' }}>
                  <FontIcon iconName='regio' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  regio
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  alle aanvragen worden gegroepeerd<br />
                  per regio en per dag<br />
                  u kan ons contacteren voor elk adres binnen de <br />
                  de <b>belgische kempen</b>
                </div>
              </div>
            </div>
            <div className={`ms-Grid-col ms-sm12 ms-md4 ms-xl4`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`} style={{ textAlign: 'center' }}>
                  <FontIcon iconName='timespan' className={classes.fontIcon} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  tijdslot
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  enkele dagen vooraf wordt u geïnformeerd<br />
                  over het geplande uur van aankomst <br /><br />
                  past dit niet? <br />
                  dan zoeken we samen een ander moment
                </div>
              </div>
            </div>
            <div className={`ms-Grid-col ms-sm12 ms-md4 ms-xl4`} style={{ textAlign: 'center' }}>
              <div className={`ms-Grid-row`}>
                <div className={`ms-Grid-col ms-sm12`} style={{ textAlign: 'center' }}>
                  <FontIcon iconName='mobile' className={classes.fontIcon} style={{ minWidth: 60 }} />
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                  ter plaatse
                </div>
                <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                  je ziet onze trimbus verschijnen bij u thuis<br /><br />
                  lopen we ergens vertraging op?<br />
                  we brengen u zo snel mogelijk op de hoogte
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default Work;
