import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useWorkStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralLight}`,
        padding: '20px 20px 50px',
        color: `${theme.palette.neutralDark}`
    },
    header: {
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 0 15px',
        color: `${theme.palette.neutralPrimary}`
    },
    subheader: {
        textAlign: 'center',
        fontSize: 'x-large',
        fontWeight: '700',
        padding: '10px 0 15px',
        color: `${theme.palette.neutralPrimary}`
    },
    text: {
        textAlign: 'center',
        fontSize: 'large',
        color: `${theme.palette.neutralPrimary}`
    },
    fontIcon: {
        paddingTop: '10px',
        minHeight: 30,
        minWidth: 30
    }
}))

export default useWorkStyles