import { makeStyles } from "@fluentui/react/lib/Theme"

const useNavigationStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralDark}`,
        // padding: '20px 50px 20px 50px',
        color: `${theme.palette.neutralLight}`
    },
    image: {
        maxWidth: '56%',
        maxHeight: '75px',
        paddingLeft: '50px'
    },
    homeButton: {
        color: `${theme.palette.themeSecondary}`,
        textAlign: 'left',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '10px 0 0'
    },
    defaulttext: {
        textAlign: 'left',
        fontSize: 'large',
        fontWeight: '500',
        cursor: 'pointer',
        padding: '37px 50px 20px 0 !important'
    },
    homeButtonMobile:{
        color: `${theme.palette.themeSecondary}`,
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 50px 20px 50px',
    },
    generalMobile: {
        backgroundColor: `${theme.palette.neutralDark}`,
        color: `${theme.palette.neutralLight}`
    },
    navigationItemMobile:{
        padding: '10px',
        fontSize: 'large',
        cursor: 'pointer'
    }
}))

export default useNavigationStyles