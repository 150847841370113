import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { ApiService } from '../services'
import { IPostinfoDetail } from '../types/postinfoDetail.types'

import { useConfigurationStore } from '.'

const getPostinfo = (service: ApiService, postalcode: string): Promise<IPostinfoDetail> => {
    return service.fetchPostalCodeDetail(postalcode).then(response => response.data as IPostinfoDetail)
}

export default function useBelgianPostalCodeDetail(postalcode?: string) {
    const apiService: ApiService = useConfigurationStore(state => state.apiService)

    return useQuery<IPostinfoDetail, AxiosError>(`postinfo/${postalcode}`, () =>
        getPostinfo(apiService, postalcode as string), {
            enabled: !!postalcode
        })
}