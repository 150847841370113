import * as React from 'react'
import usePricingStyles from './Pricing.styles'
import banner from './../../../images/AdobeStock_130950231.jpeg'
import { FontIcon } from '@fluentui/react/lib/components/Icon'
import { DefaultButton } from '@fluentui/react/lib/components/Button'

interface PricingProps {
  onShowPanel: (isPanelOpen: boolean) => void

}

const Pricing: React.FC<PricingProps> = ({ onShowPanel }) => {
  const classes = usePricingStyles()

  return (
    <div className={`ms-Grid ${classes.general}`} dir="ltr">
      <div className={`ms-Grid-row`}>
        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6`} style={{ padding: '0' }}>
          <div className={`${classes.info}`}>
            <div className={classes.header}>
              prijslijst
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12`} style={{ textAlign: 'center' }}>
                <FontIcon iconName='cost' className={classes.fontIcon} />
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.text}`} style={{ textAlign: 'center' }}>
                vaste <b>€15</b> <u>binnen een straal</u> van <b>15km</b> <br />
                van ons adres tot bij u thuis
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                is de afstand groter?
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.text}`} style={{ textAlign: 'center' }}>
                dan betaalt u <b>€1</b> <u>per gereden kilometer</u> <br />
                bovenop het vaste bedrag<br />
                we berekenen de <b>kortste route</b> en dit wordt ook uw <b>vaste prijs</b>
              </div>
            </div>
            <br />
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.subheader}`}>
                prijs/ras
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.text}`}>
                <DefaultButton
                  text='toon de prijslijst*'
                  onClick={() => onShowPanel(true)}
                />
              </div>
            </div>
            <div className={`ms-Grid-row`}>
              <div className={`ms-Grid-col ms-sm12 ${classes.textremark}`}>
                <br />
                <br />
                <br />
                *prijzen zoals weergegeven zijn voor een <b>all inclusive</b> behandeling<br />
                aangepaste kostprijs voor gepersonaliseerde behandelingen kunnen vrijblijvend aangevraagd worden
              </div>
            </div>
          </div>
        </div>
        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6`} style={{ padding: '0' }}>
          <img className={classes.image} src={banner} />
        </div>
      </div>
    </div>
  )
}

export default Pricing;
