import * as React from 'react'

import { ThemeProvider } from '@fluentui/react'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { QueryClient, QueryClientProvider } from 'react-query'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { Audience } from './components/Sections/Audience'
import { Contact } from './components/Sections/Contact'
import { Footer } from './components/Sections/Footer'
import { Home } from './components/Sections/Home'
import { Navigation } from './components/Sections/Navigation'
import { Pricing } from './components/Sections/Pricing'
import { Service } from './components/Sections/Employment'
import { Gallery } from './components/Sections/Gallery'
import { Work } from './components/Sections/Work'

import useWindowSize from './hooks/useWindowSize'

import theme from './theme'
import PricingList from './components/PricingList/PricingList'

function App() {
  const { windowHeight } = useWindowSize()
  const queryClient = new QueryClient()

  const homeRef = React.useRef<HTMLInputElement>(null)
  const serviceRef = React.useRef<HTMLInputElement>(null)
  const audienceRef = React.useRef<HTMLInputElement>(null)
  const workRef = React.useRef<HTMLInputElement>(null)
  const galleryRef = React.useRef<HTMLInputElement>(null)
  const pricingRef = React.useRef<HTMLInputElement>(null)
  const contactRef = React.useRef<HTMLInputElement>(null)

  const [showPanel, setShowPanel] = React.useState<boolean>(false)

  return (
    <>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <Navigation
              homeRef={homeRef}
              serviceRef={serviceRef}
              audienceRef={audienceRef}
              workRef={workRef}
              galleryRef={galleryRef}
              pricingRef={pricingRef}
              contactRef={contactRef}
            />
            <SimpleBar style={{ maxHeight: windowHeight - 82 }}>
              <div ref={homeRef}>
                <Home />
              </div>
              <div ref={serviceRef}>
                <Service />
              </div>
              <div ref={audienceRef}>
                <Audience />
              </div>
              <div ref={workRef}>
                <Work />
              </div>
              <div ref={galleryRef}>
                <Gallery />
              </div>
              <div ref={pricingRef}>
                <Pricing
                  onShowPanel={(isPanelOpen) => setShowPanel(isPanelOpen)}
                />
              </div>
              <div ref={contactRef}>
                <Contact />
              </div>
              <Footer />
            </SimpleBar>
          </div>
        </QueryClientProvider>
      </ThemeProvider>

      <Panel
        headerText='prijslijst'
        isOpen={showPanel}
        onDismiss={() => setShowPanel(false)}
        closeButtonAriaLabel='Close'
        isLightDismiss
        type={PanelType.smallFixedFar}
      >
        <PricingList />
      </Panel>

    </>
  )
}

export default App