import { AxiosInstance, AxiosResponse } from 'axios'

import { ICityinfo } from '../types/cityinfo.types'
import { IPostinfo } from '../types/postinfo.types'
import { IPostinfoDetail } from '../types/postinfoDetail.types'

class ApiService{
    private apiInstance: AxiosInstance

    constructor(apiInstance: AxiosInstance){
        this.apiInstance = apiInstance
    }

    public fetchPostalCodes(): Promise<AxiosResponse<IPostinfo>> {
        return this.apiInstance.get(`/v1/postinfo`)
    }

    public fetchPostalCodeDetail(postalcode: string): Promise<AxiosResponse<IPostinfoDetail>> {
        return this.apiInstance.get(`/v1/postinfo/${postalcode}`)
    }

    public fetchCities(): Promise<AxiosResponse<ICityinfo>> {
        return this.apiInstance.get('/v1/gemeenten')
    }
}

export default ApiService