import * as React from 'react'
import useHomeStyles from './Home.styles'
import banner from './../../../images/AdobeStock_189472519.png'

interface HomeProps { }

const Home: React.FC<HomeProps> = () => {
  const classes = useHomeStyles()

  return (
    <div className={`Home`}>
      <img className={classes.general} src={banner} />
    </div>
  )
}

export default Home;
