import { PartialTheme } from "@fluentui/react/lib/Theme"

const theme: PartialTheme = {
    components: {

    },
    defaultFontStyle: {
        fontFamily: 'mendl-sans-dusk, Verdana, sans-serif',
        fontWeight: '400',
        fontStyle: 'normal'
    },
    palette: {
        themePrimary: '#BBA14F',
        themeSecondary: '#E2CFE5',
        neutralDark: '#1A1E29',
        neutralPrimary: '#3B404F',
        neutralLight: '#F2F7FA'
    }
}

export default theme