import * as React from 'react'

export default function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState<{ windowHeight: number, windowWidth: number }>({ windowHeight: window.innerHeight, windowWidth: window.innerWidth })

    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}