import * as React from 'react'

import { useMediaQuery } from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'

import useNavigationStyles from './Navigation.styles'

import logo from './../../../images/logo-small.png'

interface NavigationProps {
  homeRef: React.RefObject<HTMLInputElement>
  serviceRef: React.RefObject<HTMLInputElement>
  audienceRef: React.RefObject<HTMLInputElement>
  workRef: React.RefObject<HTMLInputElement>
  galleryRef: React.RefObject<HTMLInputElement>
  pricingRef: React.RefObject<HTMLInputElement>
  contactRef: React.RefObject<HTMLInputElement>
}

const Navigation: React.FC<NavigationProps> = ({ homeRef, serviceRef, audienceRef, workRef, galleryRef, pricingRef, contactRef }) => {
  const classes = useNavigationStyles()
  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const executeScroll = (ref: React.RefObject<HTMLInputElement>) => {
    setIsOpen(false)

    if (ref.current)
      ref.current.scrollIntoView()
  }

  const handleStateChange = (state: any) => {
    setIsOpen(state.isOpen)
  }

  const menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '30px',
      height: '22px',
      left: '30px',
      top: '30px'
    },
    bmBurgerBars: {
      background: '#E2CFE5'
    },
    bmBurgerBarsHover: {
      background: '#3B404F'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#F2F7FA'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#3B404F',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#F2F7FA',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  return (
    <>
      {isBigScreen ?
        <div className={`ms-Grid`} dir="ltr" >
          <div className={`ms-Grid-row ${classes.general}`}>
            <div className={`ms-Grid-col ms-sm5 ${classes.homeButton}`} onClick={() => executeScroll(homeRef)}>
              <img className={classes.image} src={logo} />
            </div>
            <div className={`ms-Grid-col ms-sm7 ${classes.defaulttext}`}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div onClick={() => executeScroll(serviceRef)}>diensten</div>
                <div onClick={() => executeScroll(audienceRef)}>doelgroep</div>
                <div onClick={() => executeScroll(workRef)}>werkwijze</div>
                <div onClick={() => executeScroll(galleryRef)}>foto's</div>
                <div onClick={() => executeScroll(pricingRef)}>prijslijst</div>
                <div onClick={() => executeScroll(contactRef)}>contact</div>
              </div>
            </div>
          </div>
        </div >
        :
        <>
          <div className={`ms-Grid`} dir="ltr" >
            <div className={`ms-Grid-row ${classes.generalMobile}`}>
              <div className={`ms-Grid-col ms-sm1`}>
                <Menu
                  width={200}
                  styles={menuStyles}
                  isOpen={isOpen}
                  onStateChange={(state) => handleStateChange(state)}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(serviceRef)}>diensten</div>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(audienceRef)}>doelgroep</div>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(workRef)}>werkwijze</div>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(galleryRef)}>foto's</div>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(pricingRef)}>prijslijst</div>
                    <div
                      className={classes.navigationItemMobile}
                      onClick={() => executeScroll(contactRef)}>contact</div>
                  </div>
                </Menu>
              </div>
              <div className={`ms-Grid-col ms-sm11 ${classes.homeButtonMobile}`} onClick={() => executeScroll(homeRef)}>
                BigBooh
              </div>
            </div>
          </div >
        </>
      }
    </>
  )
}

export default Navigation
