import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useFooterStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.themeSecondary}`,
        padding: '20px',
        color: `${theme.palette.neutralDark}`,
        textAlign: "center"
    },
    link:{
        color: `${theme.palette.neutralDark}`
    }
}))

export default useFooterStyles