import React from 'react'
import { registerIcons } from '@fluentui/react/lib/Styling'

registerIcons({
	icons: {
		'sizes': (
			<svg viewBox="0 0 231.3 78.3">
				<g fill='#BBA14F'>
					<path d="M202.3,40.1c1.6,1.4,2.6,3.1,3.6,4.8c1.2,2.1,2.5,4.1,3.7,6.2c0.4,0.7,1,1.2,1.7,1.4c2.8,1,5.7,1.6,8.7,2.2
		c-0.2-0.4-0.4-0.6-0.5-0.9c-0.5-1.3,0-2.6,1.3-3.1c3.8-1.4,8.1,1,9,4.9c0.2,0.8,0.3,1.6,0,2.3c-0.6,1.9,0.1,3.6,0.3,5.4
		c0.3,2.4,0.6,4.7,0.7,7.1c0.1,1.3,0.2,2.5,0.3,3.8c0.3,2-0.3,3.3-1.7,3.9c-1.7,0.7-3.5,0.2-4.6-1.3c-1.1-1.6-2.3-3.2-3.3-4.9
		c-0.7-1.2-1.7-1.6-2.9-1.7c-2.8-0.2-5.6-0.2-8.5,0.2c-0.8,0.1-1.3,0.4-1.8,1.1c-1.1,1.7-2.2,3.5-3.4,5.1c-1.2,1.6-2.8,2.1-4.5,1.6
		c-1.2-0.4-1.9-1.3-2-2.6c-0.3-3.2-0.2-6.4-0.3-9.6c-0.1-1.8,0-3.7,0-5.5c0-0.7-0.2-1-0.9-0.9c-1.1,0-2.3,0-3.4,0
		c-1.2,0-2.2-0.5-2.8-1.6c-1.1-1.8-1.8-3.7-2.2-5.7c-0.3-1.8,0.3-2.9,2-3.5c1.1-0.4,2.2-0.6,3.4-0.8c0.6-0.1,1.1-0.4,1.5-0.9
		c1.2-1.3,2.3-2.6,1.9-4.6c-0.1-0.3,0.1-0.6,0.1-0.9c0.3-2.2,2.1-3,4-1.8C202,39.8,202.1,39.9,202.3,40.1z M200.6,70.8
		c0,1.4,0,2.9,0.2,4.3c0.2,1,0.9,1.4,1.8,1c0.4-0.2,0.7-0.5,0.9-0.9c1.2-1.8,2.5-3.6,3.7-5.5c0.4-0.7,1-1.1,1.8-1.3
		c3.5-0.7,7.1-0.6,10.6-0.3c1.8,0.2,3,0.9,3.8,2.5c0.5,0.8,1,1.5,1.6,2.3c0.6,0.9,1.2,1.8,1.8,2.6c0.5,0.6,1.1,0.9,1.8,0.6
		c0.7-0.3,0.6-0.9,0.5-1.5c-0.1-1.3-0.2-2.6-0.3-3.9c-0.2-2.8-0.5-5.6-1-8.4c-0.3-1.5-0.5-2.9-0.1-4.5c0.7-2.7-0.9-4.6-3.7-5.1
		c-0.8-0.1-1.6-0.3-2.4,0.3c1.2,1.2,2.3,2.5,1.7,4.5c-0.7-0.1-1.4-0.2-2.1-0.3c-3.6-0.7-7.1-1.4-10.5-2.6c-1.2-0.4-2.2-1.1-2.8-2.2
		c-0.6-1.2-1.4-2.4-2.1-3.5c-1.3-2.1-2.4-4.3-3.9-6.1c-0.4-0.5-0.8-1.1-1.6-1.2c-0.3,0.4-0.3,0.8-0.3,1.2c0.4,2.2-0.7,3.8-2.1,5.3
		c-0.2,0.2-0.5,0.5-0.7,0.8c-0.6,0.8-1.3,1.1-2.3,1.3c-1,0.2-2.1,0.5-3.1,0.7c-0.7,0.2-1,0.6-0.8,1.4c0.4,1.5,0.9,2.8,1.6,4.2
		c0.4,0.8,1,1.2,1.9,1.1c1.4-0.1,2.9-0.1,4.3,0c1.3,0,1.5,0.3,1.5,1.5C200.6,62.8,200.5,66.7,200.6,70.8z"/>
				</g>
				<g fill='#BBA14F'>
					<path d="M27,1.8c3.2,2.8,5.2,6.2,7.2,9.7c2.5,4.1,5,8.2,7.3,12.4c0.8,1.4,1.9,2.3,3.4,2.9c5.7,2,11.5,3.3,17.5,4.4
		c-0.4-0.7-0.8-1.3-1-1.9c-1.1-2.6,0.1-5.2,2.7-6.1c7.6-2.8,16.3,1.9,18,9.8c0.3,1.5,0.5,3.2,0.1,4.6c-1.2,3.7,0.2,7.2,0.6,10.8
		c0.6,4.7,1.2,9.5,1.5,14.2c0.2,2.5,0.3,5,0.7,7.5c0.5,4.1-0.5,6.6-3.4,7.7c-3.4,1.4-7,0.5-9.2-2.6c-2.3-3.2-4.6-6.4-6.6-9.7
		c-1.4-2.3-3.3-3.3-5.8-3.4c-5.6-0.4-11.3-0.5-16.9,0.3c-1.6,0.2-2.7,0.9-3.5,2.2c-2.2,3.5-4.5,6.9-6.9,10.3
		C30.2,78,27,79,23.7,77.9c-2.4-0.9-3.8-2.5-4-5.2C19,66.4,19.2,60,19,53.6c-0.1-3.7-0.1-7.3,0-11c0-1.4-0.4-1.9-1.8-1.9
		c-2.3,0.1-4.5,0-6.8,0c-2.5,0-4.4-1.1-5.7-3.1c-2.2-3.5-3.7-7.4-4.5-11.5c-0.7-3.5,0.7-5.7,4-7c2.2-0.8,4.5-1.3,6.8-1.7
		c1.3-0.2,2.2-0.9,3-1.8c2.3-2.6,4.7-5.2,3.8-9.1C17.7,6,17.9,5.3,18,4.7c0.7-4.5,4.2-6,8-3.6C26.3,1.3,26.6,1.5,27,1.8z M23.5,63.2
		c0,2.9-0.1,5.7,0.4,8.6c0.3,2,1.8,2.8,3.6,1.9c0.8-0.4,1.4-1.1,1.8-1.8c2.4-3.6,4.9-7.3,7.3-11c0.9-1.4,2-2.2,3.6-2.5
		c7.1-1.3,14.1-1.3,21.2-0.6c3.5,0.4,5.9,1.9,7.6,4.9c0.9,1.6,2.1,3.1,3.1,4.6c1.2,1.8,2.4,3.5,3.6,5.3c0.9,1.3,2.2,1.7,3.7,1.1
		c1.5-0.6,1.1-1.8,1-3.1c-0.3-2.6-0.4-5.2-0.6-7.7c-0.4-5.6-0.9-11.2-1.9-16.8c-0.5-3-1.1-5.7-0.3-8.9c1.3-5.4-1.9-9.2-7.4-10.1
		c-1.6-0.3-3.2-0.7-4.9,0.5c2.5,2.5,4.5,5.1,3.3,9c-1.5-0.2-2.9-0.4-4.2-0.7c-7.1-1.3-14.2-2.8-21.1-5.2c-2.4-0.8-4.4-2.1-5.6-4.5
		c-1.3-2.4-2.7-4.7-4.2-7c-2.6-4.1-4.7-8.5-7.8-12.3c-0.9-1-1.7-2.2-3.2-2.4c-0.7,0.8-0.6,1.6-0.5,2.4c0.8,4.4-1.3,7.6-4.1,10.5
		c-0.5,0.5-0.9,1-1.3,1.5c-1.1,1.5-2.7,2.3-4.5,2.6c-2.1,0.4-4.1,1-6.2,1.5c-1.5,0.4-2,1.3-1.6,2.8c0.7,2.9,1.8,5.7,3.2,8.4
		c0.8,1.6,2,2.3,3.8,2.3c2.9-0.1,5.7-0.1,8.6,0c2.6,0.1,2.9,0.6,3,3.1C23.5,47.3,23.4,55.1,23.5,63.2z"/>
				</g>
				<g fill='#BBA14F'>
					<path d="M125.3,21c2.4,2.1,3.9,4.7,5.4,7.2c1.9,3.1,3.7,6.2,5.5,9.3c0.6,1.1,1.4,1.7,2.6,2.1c4.2,1.5,8.6,2.4,13.1,3.3
		c-0.3-0.5-0.6-1-0.8-1.4c-0.8-1.9,0-3.9,2-4.6c5.7-2.1,12.2,1.4,13.5,7.3c0.2,1.1,0.4,2.4,0.1,3.4c-0.9,2.8,0.2,5.4,0.5,8.1
		c0.4,3.6,0.9,7.1,1.1,10.7c0.1,1.9,0.3,3.8,0.5,5.6c0.4,3.1-0.4,4.9-2.5,5.8c-2.6,1-5.3,0.3-6.9-1.9c-1.7-2.4-3.4-4.8-5-7.3
		c-1-1.7-2.5-2.5-4.4-2.6c-4.2-0.3-8.5-0.3-12.7,0.2c-1.2,0.2-2,0.7-2.6,1.7c-1.7,2.6-3.3,5.2-5.2,7.7c-1.8,2.4-4.2,3.2-6.7,2.3
		c-1.8-0.6-2.8-1.9-3-3.9c-0.5-4.8-0.3-9.6-0.5-14.4c-0.1-2.7-0.1-5.5,0-8.2c0-1-0.3-1.4-1.4-1.4c-1.7,0.1-3.4,0-5.1,0
		c-1.9,0-3.3-0.8-4.2-2.4c-1.6-2.7-2.8-5.5-3.4-8.6c-0.5-2.7,0.5-4.3,3-5.3c1.6-0.6,3.4-0.9,5.1-1.3c1-0.2,1.6-0.7,2.2-1.4
		c1.7-2,3.5-3.9,2.9-6.8c-0.1-0.4,0.1-0.9,0.2-1.3c0.5-3.4,3.1-4.5,6-2.7C124.8,20.6,125,20.7,125.3,21z M122.7,67
		c0,2.1-0.1,4.3,0.3,6.4c0.2,1.5,1.3,2.1,2.7,1.4c0.6-0.3,1-0.8,1.4-1.3c1.8-2.7,3.7-5.5,5.5-8.2c0.7-1,1.5-1.6,2.7-1.9
		c5.3-1,10.6-1,15.9-0.4c2.6,0.3,4.5,1.4,5.7,3.7c0.7,1.2,1.5,2.3,2.3,3.5c0.9,1.3,1.8,2.6,2.7,3.9c0.7,1,1.6,1.3,2.8,0.8
		c1.1-0.5,0.9-1.4,0.8-2.3c-0.2-1.9-0.3-3.9-0.5-5.8c-0.3-4.2-0.7-8.4-1.4-12.6c-0.4-2.2-0.8-4.3-0.2-6.7c1-4-1.4-6.9-5.6-7.6
		c-1.2-0.2-2.4-0.5-3.6,0.4c1.8,1.9,3.4,3.8,2.5,6.7c-1.1-0.2-2.1-0.3-3.2-0.5c-5.3-1-10.6-2.1-15.8-3.9c-1.8-0.6-3.3-1.6-4.2-3.3
		c-1-1.8-2.1-3.5-3.1-5.3c-1.9-3.1-3.5-6.4-5.9-9.2c-0.6-0.8-1.3-1.6-2.4-1.8c-0.5,0.6-0.5,1.2-0.4,1.8c0.6,3.3-1,5.7-3.1,7.9
		c-0.3,0.4-0.7,0.7-1,1.1c-0.8,1.2-2,1.7-3.4,2c-1.6,0.3-3.1,0.7-4.6,1.1c-1.1,0.3-1.5,0.9-1.2,2.1c0.6,2.2,1.3,4.3,2.4,6.3
		c0.6,1.2,1.5,1.8,2.9,1.7c2.1-0.1,4.3-0.1,6.4,0c1.9,0.1,2.2,0.4,2.3,2.3C122.7,55.1,122.6,60.9,122.7,67z"/>
				</g>
			</svg>

		)
	}
})

export default registerIcons