import { makeStyles } from '@fluentui/react/lib/Theme'
import theme from '../../theme'

const usePricingListStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralLight}`
    },
    header: {
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 0 15px',
        color: `${theme.palette.neutralPrimary}`
    }
}))

export default usePricingListStyles