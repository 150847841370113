import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useContactStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralDark}`,
        padding: '20px',
        color: `${theme.palette.neutralLight}`
    },
    header: {
        color: `${theme.palette.themePrimary}`,
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 0 15px',
    },
    center: {
        textAlign: 'center',
        paddingBottom: '25px',
        fontSize: 'large'
    },
    contactBlock:{
        padding: '20px 30px 20px 30px'
    },
    contactheader: {
        textAlign: 'left',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '0 0 5px',
        color: `${theme.palette.themeSecondary}`
    },
    contactsubheader: {
        textAlign: 'left',
        fontSize: 'x-large',
        fontWeight: '700',
        padding: '20px 0 5px',
    },
    defaulttext: {
        textAlign: 'left',
        fontSize: 'medium',
        fontWeight: '500',
        color: `${theme.palette.themeSecondary}`
    },
    defaulttextheader: {
        textAlign: 'left',
        fontSize: 'medium',
        fontWeight: '500'
    },
    textremark: {
        fontSize: 'FontSizes.size12'
    }
}))

export default useContactStyles