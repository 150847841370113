import React from 'react'
import { registerIcons } from '@fluentui/react/lib/Styling'

registerIcons({
	icons: {
		'regio': (
			<svg viewBox="0 0 141.7 141.7">
				<g id="DkgZtG_00000124845294293162756140000016358288511326186918_">
					<g fill='#BBA14F'>
						<path d="M70.9,119.6c-4.1-4.8-8-9.2-11.7-13.7c-9.3-11.5-18.1-23.3-25.1-36.3c-2.9-5.4-5.3-10.9-6.6-16.9
			c-1.4-6.3-0.7-12.5,1.2-18.5C33.4,18.9,43.5,8.5,58.9,4c22-6.4,44.3,4.4,53.3,25.4c4.9,11.4,4.5,22.8-0.9,34
			c-6,12.4-13.7,23.6-22.2,34.3C83.3,105,77.1,112.1,70.9,119.6z M70.8,107.3c0.4-0.5,0.6-0.7,0.8-1c5.1-6.3,10.3-12.5,15.3-18.9
			c6.6-8.6,12.5-17.7,17.3-27.6c5.1-10.5,4.5-20.8-1.1-30.7C93.2,11.5,72.4,5.4,54.4,14.4C41.9,20.7,33.8,35,35,48.8
			c0.5,5,2.4,9.5,4.6,13.9c6.4,12.9,14.9,24.5,23.8,35.7C65.8,101.3,68.2,104.2,70.8,107.3z"/>
						<path d="M103.6,108.2c0.4-2.7,0.7-5.1,1.2-7.9c3.8,0.7,7.4,1.3,11.1,2.1c6.5,1.4,13,3.1,18.7,6.6c1.8,1.1,3.6,2.5,5,4.2
			c3.1,3.6,2.9,8.1-0.3,11.7c-2.7,3.1-6.3,4.9-10,6.4c-7.7,3.1-15.7,4.6-23.8,5.8c-12.5,1.9-25.1,2.6-37.7,2.4
			c-16.6-0.3-33.2-1.5-49.2-6.1c-5-1.4-9.8-3.2-13.9-6.4c-5.9-4.7-6.1-11-0.3-15.9c1-0.9,2.1-1.6,3.3-2.3c6.1-3.4,12.8-5.2,19.6-6.6
			c3.1-0.7,6.2-1.1,9.7-1.8c0.4,2.7,0.8,5.3,1.2,7.9c-3.3,0.6-6.3,1.1-9.4,1.8c-5.7,1.2-11.3,2.6-16.4,5.3c-1.1,0.6-2.1,1.3-3,2
			c-1.2,0.9-1.3,2,0,2.8c1.9,1.2,3.9,2.5,6,3.4c7.2,3,14.8,4.4,22.5,5.6c15,2.3,30.2,2.6,45.4,2c13.5-0.6,27-2,40-6.2
			c3.2-1,6.3-2.7,9.2-4.4c2.1-1.2,2-2.2,0-3.6c-3.9-2.9-8.5-4.4-13.2-5.5c-4.7-1.1-9.4-1.9-14.1-2.9
			C104.4,108.5,104,108.4,103.6,108.2z"/>
						<path d="M85.8,46.6c0,8.4-6.8,15.1-15.3,15.1c-8.1,0-15-7-15-15.1c0-8.6,7.5-15.3,15.5-15.1C79.2,31.7,85.9,38.4,85.8,46.6z
			 M77.8,46.6c0-3.9-3.2-7.1-7.1-7c-3.9,0-7,3.2-7,7.1c0,3.9,3.2,7.1,7.1,7C74.7,53.6,77.8,50.5,77.8,46.6z"/>
					</g>
				</g>
			</svg>
		),
		'mobile': (
			<svg viewBox="0 0 141.7 59.7">
				<g id="vpYhzc_00000012455826483209136190000004604173969560981673_">
					<g fill='#BBA14F'>
						<path d="M140.4,44.8c0.5,1.2,1.4,2.4,1.3,3.5c-0.3,1.9-1.9,2.5-3.8,2.5c-2.1,0-4.2,0-6.5,0c0.1-3.3-0.4-6.5-3-8.5
			c-1.5-1.2-3.5-2.3-5.4-2.6c-6.4-1-11.3,2.9-14.5,10.9c-16.8,0-33.7,0-50.6,0c-0.2-1.5-0.2-2.9-0.5-4.2c-1.3-5.1-6.5-8-11.9-6.7
			c-5,1.3-8.4,4.4-9.9,9.3c-0.4,1.4-1,1.7-2.3,1.7c-2-0.1-4.1,0-6.1,0c-3-0.1-3.9-1.2-3.6-4.1c0.1-1,0.2-1.9,0.2-3.1
			c-1.2,0-2.4,0-3.5,0c-1.5,0-3-0.1-3.2-2c-0.1-1.7,1.2-2.8,3.3-2.8c3.4,0,6.8,0,10.2,0c0.5,0,1.1,0,1.6,0c1.6-0.2,2.7-1,2.7-2.7
			c0-1.8-1.4-2.1-2.8-2.1c-7.4,0-14.8,0-22.2,0c-1.6,0-3.6,0.5-3.8-2C6.1,30,7.7,29,10.4,29c14.4,0,28.8,0,43.2,0
			c0.6,0,1.2,0.1,1.8,0c1.7-0.2,2.9-1,2.9-2.9c-0.1-1.9-1.5-2-2.9-2c-17.2,0-34.4,0-51.6,0c-1.7,0-3.5,0.3-3.8-2.1
			c-0.2-1.6,1.5-2.8,3.9-2.8c21.3,0,42.5,0,63.8,0c0.8,0,1.5,0,2.3,0c1.7-0.1,2.7-0.9,2.8-2.6c0.1-1.9-1.3-2.1-2.8-2.1
			c-16.8,0-33.6,0-50.4,0c-1.7,0-3.7,0.5-3.9-2.1c-0.1-1.7,1.4-2.7,4.1-2.7c9.3,0,18.5,0,27.8,0c0.7,0,1.6,0.2,2-0.1
			c0.9-0.8,1.6-1.9,2.4-2.9c-0.9-0.6-1.8-1.8-2.7-1.8c-5.4-0.2-10.9-0.1-16.3-0.1c-1.6,0-3.1-0.2-3-2.1C30,0.9,31.2,0,33,0
			c1,0,2,0,2.9,0c22.8,0,45.5,0,68.3,0c2.2,0,4.4,0.2,6.5,0.7c3.4,0.9,5.9,2.9,7.3,6.2c1.9,4.2,4,8.4,5.8,12.7
			c0.8,1.8,1.9,3.1,3.9,3.7c3.4,1.2,6.7,2.6,10.1,4c3.1,1.3,4,3.1,3.5,6.4c-0.6,3.7-1.1,7.4-1.6,11.2
			C140,44.9,140.2,44.8,140.4,44.8z M109.4,22.1c3.2,0,6.3,0,9.5,0c1.7,0,2.2-0.7,1.4-2.3c-1.3-2.9-2.5-5.8-4-8.6
			c-0.5-0.9-1.8-1.8-2.9-1.9c-3.8-0.2-7.5-0.1-11.3-0.1c-1.6,0-2.5,0.6-2.7,2.3c-0.3,2.8-0.8,5.5-1.2,8.3c-0.3,1.7,0.5,2.3,2.1,2.3
			C103.3,22,106.4,22.1,109.4,22.1z"/>
						<path d="M55.7,49.4c0,5.4-4.7,10.2-10.2,10.3c-4.5,0-7.9-3.3-8-7.7c-0.1-5.3,4.8-10.2,10.3-10.2C52.5,41.8,55.7,44.9,55.7,49.4z
			 M46.5,54.1c1.9,0.1,3.8-1.7,3.9-3.6c0.1-1.6-1.2-3.1-2.9-3.2c-1.9-0.1-3.8,1.7-3.9,3.7C43.6,52.7,44.7,54,46.5,54.1z"/>
						<path d="M118.8,59.7c-4.5,0-7.9-3.4-7.8-7.9c0.1-5.4,4.9-10.1,10.4-10.1c4.5,0,7.9,3.4,7.8,7.8C129.1,55,124.2,59.8,118.8,59.7z
			 M123.8,50.3c0-1.6-1.4-3-3-3c-1.9,0-3.7,1.8-3.8,3.8c0,1.8,1.2,3,2.9,3C121.9,54.1,123.8,52.2,123.8,50.3z"/>
					</g>
				</g>
			</svg>
		),
		'timespan': (
			<svg viewBox="0 0 141.7 141.7">
				<g id="XHUHCk_00000081626098624475629460000005470704436672184240_">
					<g fill='#BBA14F'>
						<path d="M71.2,0c38.4-0.1,70.4,32.2,70.5,71c0.1,38.7-32.1,70.7-71.1,70.8c-38.3,0.1-70.5-32-70.6-70.4C-0.1,32,31.7,0.1,71.2,0z
			 M24.8,32.9C8.4,51.2,5.1,82.5,22.2,106.1c17.2,23.7,48.7,31.6,74.8,18.8c26.5-13,39.8-43,31.4-71.2c-7-23.5-32.7-44.2-52-41.9
			c-0.1,0.2-0.2,0.5-0.2,0.7c0,16.8,0,33.6-0.1,50.3c0,2.3,0.9,2.9,3,2.8c6.2-0.1,12.3,0,18.5,0c0,3.7,0,7,0,10.5
			c-8.8,0-17.3,0.1-25.8-0.1c-1.9-0.1-4.2-1.1-5.6-2.4c-9.2-8.9-18.1-18-27.2-27C34.3,41.9,29.5,37.5,24.8,32.9z"/>
					</g>
				</g>
			</svg>
		)
	}
})

export default registerIcons