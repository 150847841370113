import * as  React from 'react'

import { ComboBox, IComboBox, IComboBoxOption, IComboBoxProps } from '@fluentui/react/lib/ComboBox'

import { useField } from 'formik'

interface IComboFieldProps {
    fieldname: string
    selectedCode?: string | number | undefined
}

const ComboField: React.FC<IComboFieldProps & IComboBoxProps> = (props) => {
    const [field, meta, helpers] = useField<IComboBoxOption | null>(props.fieldname)

    const handleComboChange = React.useCallback(
        (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
            helpers.setValue(option ? option : null)
        }, []
    )

    React.useEffect(() => {
        if (props.selectedCode) {
            helpers.setValue(props.options[props.selectedCode as number])
        }
    }, [props.selectedCode])

    return (
        <ComboBox
            {...props}
            errorMessage={meta.error}
            selectedKey={field.value ? field.value.key : null}
            onChange={handleComboChange}
        />
    )
}

export default ComboField