
import * as  React from 'react'

import { PrimaryButton } from '@fluentui/react/lib/Button'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { IComboBoxOption } from '@fluentui/react/lib/ComboBox'

import { Formik, Form, FormikErrors } from 'formik'
import { send } from 'emailjs-com'

import { IContactFormValues, IDays } from '../../types/form.types'
import { ComboField, InputField } from '../Form'
import useFormStyles from './ContactForm.styles'
import ContactFormCity from './ContactFormCity'

interface FormProps {
}

const ContactForm: React.FC<FormProps> = () => {
  const classes = useFormStyles()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [confirmationVisible, setConfirmationVisible] = React.useState<boolean>(false)
  const [formErrors, setFormErrors] = React.useState<FormikErrors<IContactFormValues>>({})
  const [responseError, setResponseError] = React.useState<any>()

  const initialValues: IContactFormValues = {

  }

  const dayOptions: IComboBoxOption[] = [
    { key: IDays.monday, text: IDays.monday },
    { key: IDays.tuesday, text: IDays.tuesday },
    // { key: IDays.wednesday, text: IDays.wednesday},
    // { key: IDays.thursday, text: IDays.thursday },
    // { key: IDays.friday, text: IDays.friday },
    // { key: IDays.saterday, text: IDays.saterday },
    // { key: IDays.sunday, text: IDays.sunday }
  ]

  const onSubmit = (values: IContactFormValues) => {

    const toSend = {
      from_name: values.name,
      phone: values.phoneNumber,
      street: values.street,
      postal_code: values.postalCode,
      city: values.city?.text,
      preferred_day: (values.day as IComboBoxOption)?.text,
      remark: values.remark,
      reply_to: values.email
    }

    send(
      'service_vw6pxqs', // YOUR_SERVICE_ID
      'template_wdbhcby', // YOUR_TEMPLATE_ID
      toSend, // message
      'user_ebIQWAGU5pQGhszaEp2lu' // YOUR_USER_ID
    ).then((response) => {
      setIsLoading(false)
      setConfirmationVisible(true)
    })
      .catch((err) => {
        setResponseError(err)
        setIsLoading(false)
      })
  }

  const validate = (values: IContactFormValues) => {
    let errors: FormikErrors<IContactFormValues> = {}

    if (!values.name) errors.name = 'Dit is een verplicht veld'
    else if (!/^[a-z ,.'-]+$/i.test(values.name)) errors.name = 'Gelieve een geldige naam in te vullen'
    else errors.name = undefined

    if (!values.street) errors.street = 'Dit is een verplicht veld'
    else errors.street = undefined

    if (!values.postalCode) errors.postalCode = 'Dit is een verplicht veld'
    else if (values.postalCode.length !== 4) errors.postalCode = 'Gelieve een geldige postcode in te vullen'
    else errors.postalCode = undefined

    if (!values.city) errors.city = 'Dit is een verplicht veld'
    else errors.city = undefined

    if (!values.phoneNumber) errors.phoneNumber = 'Dit is een verplicht veld'
    else if (!/^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/i.test(values.phoneNumber.replace(' ', ''))) errors.phoneNumber = 'Gelieve een geldig telefoonnummer in te geven'
    else errors.phoneNumber = undefined

    if (!values.email) errors.email = 'Dit is een verplicht veld'
    else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,5}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) errors.email = 'Gelieve een geldig email adres in te vullen'
    else errors.email = undefined

    if (errors.name || errors.street || errors.postalCode || errors.city || errors.phoneNumber || errors.email) {
      setFormErrors(errors)
      setIsLoading(false)
    } else onSubmit(values)
  }

  return (
    <div>
      {!confirmationVisible ?
        <Formik
          initialValues={initialValues}
          onSubmit={(values: IContactFormValues) => {
            setIsLoading(true)
            validate(values)
          }}
        >
          {({ values }) => (
            <Form className={`ms-Grid-row ${classes.form}`} dir='ltr'>
              {responseError &&
                <MessageBar key={responseError} messageBarType={MessageBarType.blocked}>
                  {responseError}
                </MessageBar>
              }
              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <InputField
                    fieldname='name'
                    placeholder='naam'
                  />
                  {formErrors.name ? (
                    <MessageBar key={formErrors.name} messageBarType={MessageBarType.blocked}>
                      {formErrors.name}
                    </MessageBar>
                  ) : null}
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <InputField
                    fieldname='phoneNumber'
                    placeholder='telefoonnummer'
                  />
                  {formErrors.phoneNumber ? (
                    <MessageBar key={formErrors.phoneNumber} messageBarType={MessageBarType.blocked}>
                      {formErrors.phoneNumber}
                    </MessageBar>
                  ) : null}
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <InputField
                    fieldname='email'
                    placeholder='email'
                  />
                  {formErrors.email ? (
                    <MessageBar key={formErrors.email} messageBarType={MessageBarType.blocked}>
                      {formErrors.email}
                    </MessageBar>
                  ) : null}
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <InputField
                    fieldname='street'
                    placeholder='straat en nummer'
                  />
                  {formErrors.street ? (
                    <MessageBar key={formErrors.street} messageBarType={MessageBarType.blocked}>
                      {formErrors.street}
                    </MessageBar>
                  ) : null}
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm4'>
                  <InputField
                    fieldname='postalCode'
                    placeholder='postcode'
                    type='number'
                    min={0}
                  />
                  {formErrors.postalCode ? (
                    <MessageBar key={formErrors.postalCode} messageBarType={MessageBarType.blocked}>
                      {formErrors.postalCode}
                    </MessageBar>
                  ) : null}
                </div>
                <div className='ms-Grid-col ms-sm8'>
                  <ContactFormCity
                    postalCode={values.postalCode}
                    render={(options) => (
                      <ComboField
                        disabled={values.postalCode ? false : true}
                        fieldname='city'
                        allowFreeform
                        options={options}
                        placeholder='gemeente'
                      />
                    )}
                  />
                  {formErrors.city ? (
                    <MessageBar key={formErrors.city} messageBarType={MessageBarType.blocked}>
                      {formErrors.city}
                    </MessageBar>
                  ) : null}
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <ComboField
                    fieldname='day'
                    allowFreeform
                    options={dayOptions}
                    placeholder='kies de dag van uw voorkeur'
                  />
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm12'>
                  <InputField
                    fieldname='remark'
                    placeholder='opmerking'
                    multiline={true}
                    maxLength={250}
                  />
                </div>
              </div>

              <div className={`ms-Grid-row ${classes.formItem}`}>
                <div className='ms-Grid-col ms-sm3'>
                  <PrimaryButton
                    text={'versturen'}
                    type='submit'
                    disabled={isLoading}
                  />
                </div>
                {isLoading &&
                  <div className='ms-Grid-col ms-sm1'>
                    <Spinner
                      size={SpinnerSize.medium}
                      styles={{
                        root: {
                          marginTop: '.5em'
                        }
                      }}
                    />
                  </div>
                }
              </div>
            </Form>
          )}

        </Formik>
        :
        <div>
          Bedankt voor uw aanvraag!
        </div>
      }
    </div>
  )
}

export default ContactForm