import { makeStyles } from "@fluentui/react/lib/Theme"
import theme from "../../../theme"

const useAudienceStyles = makeStyles(theme => ({
    general: {
        backgroundColor: `${theme.palette.neutralPrimary}`
    },
    image: {
        width: '100%'
    },
    info: {
        padding: '20px',
        color: `${theme.palette.neutralLight}`
    },
    header: {
        textAlign: 'center',
        fontSize: 'xx-large',
        fontWeight: '700',
        padding: '20px 0 15px',
    },
    subheader: {
        textAlign: 'center',
        fontSize: 'x-large',
        fontWeight: '700',
        padding: '10px 0 15px',
        color: `${theme.palette.neutralLight}`
    },
    text: {
        textAlign: 'center',
        fontSize: 'large',
        color: `${theme.palette.neutralLight}`,
        paddingBottom: '30px'
    },
    fontIcon: {
        padding: '10px 0',
        minHeight: 25,
        minWidth: 75,
        cursor: 'pointer'
    }
}))

export default useAudienceStyles